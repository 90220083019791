<template>
  <div>
    <div v-if="showTitle" class="flex h-6 items-center justify-between">
      <slot name="title" :title="title">
        <div class="text-center text-sm font-semibold">{{ title }}</div>
      </slot>
      <div v-if="showLabel && !isMaxedOut">
        <slot class="transition">
          {{ label || `${percentageValue}%` }}
        </slot>
      </div>
    </div>
    <div class="flex h-7 items-center">
      <div class="bar-height w-full rounded-full bg-gray-200">
        <div
          class="bar-height flex items-center justify-end rounded-full bg-primary-default transition"
          :style="`width: ${percentageValue}%; ${fillColor}`"
        >
          <div
            class="flex content-center justify-center rounded-full bg-background-extra-light shadow-drop-lg transition"
            :class="{
              'bar-height w-4 border-primary-default': !isMaxedOut,
              'size-7': isMaxedOut,
              border: percentageValue > 0,
            }"
          >
            <transition name="scale">
              <DynamicPictogram
                v-if="isMaxedOut"
                name="check-mark"
                class="self-center justify-self-center font-bold text-primary-default"
                size="ml"
              />
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  max: {
    type: Number,
    required: true,
  },
  progress: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  showLabel: {
    type: Boolean,
    required: false,
    default: true,
  },
  showTitle: {
    type: Boolean,
    default: true,
  },
  fillColor: {
    type: String,
    default: '',
  },
});

const percentageValue = computed(() => {
  const progress = 1 - (props.max - props.progress) / props.max;
  return progress < 1 ? Math.floor(progress * 100) : 100;
});
const isMaxedOut = computed(() => props.progress >= props.max);
</script>
<style lang="postcss" scoped>
.bar-height {
  @apply h-3;
}
.transition {
  @apply transition-all duration-700 ease-in-out;
}
.scale-enter-active {
  animation: scale 0.7s;
}

.scale-leave-active {
  animation: scale 0.4s reverse;
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
